import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AddToolkitCard = ({ onClick }) => {
  const {t} = useTranslation();
  return (
    <div onClick={onClick} className="card text-center border-dashed p-4 cursor-pointer">
      <div className="card-body d-flex flex-column align-items-center justify-content-center">
        <div
          className="d-flex justify-content-center align-items-center rounded-circle border border-secondary"
          style={{
            width: '80px',
            height: '80px',
            fontSize: '40px',
            color: '#6c757d', 
          }}
        >
          +
        </div>
        <p className="mt-3 text-secondary">{t("addAnotherToolkit")}</p>
      </div>
    </div>
  );
};


AddToolkitCard.propTypes = {
    onClick:PropTypes.func.isRequired,
};


export default AddToolkitCard;
