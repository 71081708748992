import React, { useState, useEffect } from "react";
import StudentHeader from "../header";
import StudentSidebar from "../sidebar";
import { useTranslation } from "react-i18next";
import { sendStudentQuery } from "../../../api/studentApi"; 
import { Alert } from "react-bootstrap"; 

const StudentSupport = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    question: "",
    screenshot: {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    const storedName = localStorage.getItem("Name");
    const storedEmail = localStorage.getItem("Email");

    setFormData((prevData) => ({
      ...prevData,
      name: storedName || "",
      email: storedEmail || "",
    }));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        const fileName = file.name;
        setFormData({ ...formData, screenshot: {...formData.screenshot,
          files: [base64Image],
          fileNames: [fileName],
        } });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const queryData = {
      name: formData.name,
      email: formData.email,
      query_description: formData.question,
      attachments: formData.screenshot,
    };

    try {
      const response = await sendStudentQuery(queryData);
      if (response?.success) {
        setAlertMessage(t("querySubmittedSuccess"));
        setAlertType("success");
        setFormData({
          name: formData.name,
          email: formData.email,
          question: "",
          screenshot: null,
        });
      } else {
        setAlertMessage(t("querySubmissionFailed"));
        setAlertType("danger");
      }
    } catch (error) {
      console.error("Error submitting query:", error);
      setAlertMessage(t("querySubmissionFailed"));
      setAlertType("danger");
    } finally {
      setIsSubmitting(false);
    }

    // Clear alert after 5 seconds
    setTimeout(() => setAlertMessage(null), 10000);
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper">
        {/* Header */}
        <StudentHeader activeMenu={"Support"} />
        {/* /Header */}

        {/* Page Content */}
        <div className="page-content bg-light-grayish-blue">
          <div className="container">
            <div className="row">
              {/* Sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}
              {/* Support Form */}
              <div className="col-xl-9 col-lg-9 container-with-margin" style={{ marginTop: "60px" }}>
                <div className="settings-widget mb-0">
                  <div className="settings-menu p-10">
                    <div className="profile-heading">
                      <h3>{t("support")}</h3>
                    </div>
                    {/* Alert Message */}
                    {alertMessage && (
                      <Alert
                        variant={alertType}
                        onClose={() => setAlertMessage(null)}
                        dismissible
                      >
                        {alertMessage}
                      </Alert>
                    )}
                    <form onSubmit={handleSubmit} className="personal-address">
                      <div className="row">
                        {/* Name */}
                        {/* <div className="col-sm-12 mb-3">
                          <label htmlFor="name">{t("name")}</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control read-only"
                            value={formData.name}
                            readOnly
                          />
                        </div> */}
                        {/* Email */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="email">{t("email")}</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control read-only"
                            value={formData.email}
                            readOnly
                          />
                        </div>
                        {/* Question */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="question">{t("describeYourIssue")}</label>
                          <textarea
                            id="question"
                            name="question"
                            rows="4"
                            className="form-control"
                            placeholder={t("enterYourQuestion")}
                            value={formData.question}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </div>
                        {/* Screenshot */}
                        <div className="col-sm-12 mb-3">
                          <label htmlFor="screenshot">{t("uploadScreenshot")}</label>
                          <input
                            type="file"
                            id="screenshot"
                            name="screenshot"
                            className="form-control"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        {/* Submit Button */}
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? t("submitting") : t("submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /Support Form */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default StudentSupport;
