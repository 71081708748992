import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  details: null, 
  status: 'idle', 
  error: null, 
  trainingStatus:null,
};

const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setTrainingPrograms: (state, action) => {
      state.details = action.payload; // Store training details
    },
    setStatus: (state, action) => {
      state.status = action.payload; // Set loading status
    },
    setError: (state, action) => {
      state.error = action.payload; // Store any error messages
    },
    setTrainingStatus: (state, action) => {
      state.trainingStatus = action.payload; 
    },
  },
});


export const { setTrainingPrograms, setStatus, setError,setTrainingStatus  } = trainingSlice.actions;


export default trainingSlice.reducer;
