import React from "react";
import PropTypes from "prop-types";
import { PersonalMoney,Investment } from '../../../components/imagepath';


const Toolbox = ({ onSelectToolbox }) => {

  return (
    <div className="row">
      {/* Personal Money Toolbox Card */}
      <div className="col-md-6">
        <div
          className="card shadow-sm"
          style={{ cursor: "pointer" , padding: "10px" , borderRadius: "8px",maxHeight:"415px" }}
          onClick={() => onSelectToolbox("PMOSToolkit1")}
        >
          <img
            src={PersonalMoney}
            className="card-img-top"
            alt="Personal Money Toolbox"
            style={{ height: "284px", objectFit: "cover"  }}
          />
          <div className="card-body text-center">
            <h5 className="card-title">Personal Money Toolbox</h5>
            <p className="card-text">Manage your personal finances effectively.</p>
          </div>
        </div>
      </div>

      {/* Investment Toolbox Card */}
      <div className="col-md-6">
        <div
          className="card shadow-sm"
          style={{ cursor: "pointer", padding: "10px" , borderRadius: "8px",maxHeight:"415px"}}
          onClick={() => onSelectToolbox("Program3Toolkit5")}
        >
          <img
            src={Investment}
            className="card-img-top"
            alt="Investment Toolbox"
            style={{ height: "284px", objectFit: "cover" }}
          />
          <div className="card-body text-center">
            <h5 className="card-title">Investment Toolbox</h5>
            <p className="card-text">Optimize your investments efficiently.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Toolbox.propTypes = {
    onSelectToolbox: PropTypes.func.isRequired,
  };

export default Toolbox;
