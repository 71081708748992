import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json'; 
import es from './locales/es.json'; 

const storedLang = localStorage.getItem('userLanguage'); 

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    es: {
      translation: es
    }
  },
  lng: storedLang === 'English' ? 'en' : storedLang === 'Spanish' ? 'es' : 'en', // Fallback to 'es' if no match
  fallbackLng: 'en', // default to English if no language is set
  supportedLngs: ['en', 'es'], 
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
