import React, { useState, useEffect  } from 'react';
import {calculateToolkit1} from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions} from "../../../../../utils/helper";
import PropTypes from 'prop-types';
import "./tool.css";


const ShareCalculatorTool = ({data}) => {
    
    const [ownership, setOwnership] = useState('0');
    const [calculatedData, setCalculatedData] = useState({
            market_value: '',
            shares_owned: '',
            total_amount_value: '',
            shares_value:'',
            price_value:'',
          });  


          useEffect(() => {
            const fetchInitialData = async () => {
              if (data) {
                const initialCalculatedData = await calculateToolkit1(ownership || "80"); // Default ownership to 80 if not provided
                setCalculatedData({
                  market_value: initialCalculatedData.market_value,
                  shares_owned: initialCalculatedData.shares_owned,
                  total_amount_value: initialCalculatedData.total_amount_value,
                  price_value: initialCalculatedData.price_value,
                  shares_value: initialCalculatedData.shares_value,
                });
              }
            };
            fetchInitialData();
          }, [data]);

const handleBlur = async () => {
  if (ownership) {
    const response = await calculateToolkit1(ownership);
    setCalculatedData({
      market_value: response.market_value,
      shares_owned: response.shares_owned,
      total_amount_value: response.total_amount_value,
      shares_value:response.shares_value,
      price_value:response.price_value,
    });
  }
};
      if (!data) {
        return <div>Loading...</div>;
      }

 

    // Find fields dynamically based on field names
    const getField = (fieldName) => {
        const field = data.data.find((item) => item.field_name === fieldName);
        return field ? field.field_value : '';
      };
      

      
  return (
    <div className="share-calculator">
      {/* First Header */}
      <h3 className="background-blue font-white tool-header"> <i className="fas fa-calculator"></i>{getField("lesson_header")}</h3>

      {/* Second Row - Company Information */}
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("company")}</div>
        <div className="tool-column tool-label">{getField("symbol")}</div>
        <div className="tool-column tool-label">{getField("baseline")}</div>
      </div>
      <div className="tool-row">
      <div className="tool-column">{getField("company_name")}</div>  
        <div className="tool-column">{getField("symbol_name")}</div>			  
        <div className="tool-column">{getField("baseline_value")}</div>	
      </div>

      {/* Third Row - Share Information */}
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("shares")}</div>        
        <div className="tool-column tool-label">{getField("price")}</div>		 
        <div className="tool-column tool-label">{getField("market_m_value")}</div>  
      </div>
      <div className="tool-row">
        <div className="tool-column">
        {calculatedData.shares_value}
        </div>
        <div className="tool-column">
        ${calculatedData.price_value}
        </div>
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData.market_value)}</div>
      </div>

      {/* Fourth Row - Share Calculator */}
      <h3 className="tool-header tool-label">{getField("share_calculator")}</h3>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("ownership")}</div>
        <div className="tool-column tool-label">{getField("shares_1")}</div>
        <div className="tool-column tool-label">{getField("total_amount")}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column">
          <input
            type="number"
            className="form-input"
            value={ownership}
            onChange={(e) => setOwnership(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        <div className="tool-column exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData.shares_owned)}</div>
        <div className="tool-column exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData.total_amount_value)}</div>
        
      </div>
    </div>
  );
};

ShareCalculatorTool.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ShareCalculatorTool;
