import axios from 'axios';

// Retry interceptor function
import axiosRetry from 'axios-retry';


// Pass the axios instance to the retry function  and call it
axiosRetry(axios, { 
  retries: 1, // Number of retries
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
  retryCondition(error) {
    // Conditional check the error status code
    switch (error.response.status) {
      case 500:
        return true; // Retry request with response status code 500
      case 400:
        return true; // Retry request with response status code 400
      default:
        return false; // Do not retry the others
    }
  },
});

const url = process.env.REACT_APP_API_BASE_URL;
const tenantName = process.env.REACT_APP_TENANT_NAME;

export const handleResponse = (response) => {
  const data = response.data;
  if(!data){
    return response;
  }

  if (typeof data === 'string') {
      // Handle data when it's a string
      return JSON.parse(data);
  }
  return data;
}

export const getTrainingPrograms = async (userLanguage,email) => {
  
  const requestBody = {
    body: {
      user_email: email,
      language:userLanguage
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':'0883b905-0c53-4a52-9fa4-785a191cbc4d',
        }
      });
    
    if (response.data.success) {
      const trainingPrograms = response.data.data.data;
      
      return trainingPrograms;
    } else {
      console.log('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};
export const getCourses = async (trainingProgramId) => {
  
  const requestBody = {
    body: {
      training_id:trainingProgramId
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key':'dd3a34ce-af3d-4c3c-896c-e939e3be1ca2',
        }
      });
    
    if (response.data.success) {
      const courses = response.data;
      
      return courses;
    } else {
      console.log('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};



export const getLessons = async (trainingProgramId) => {
  
  const requestBody = {
    body: {
      training_id:trainingProgramId
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'ceba7159-fab6-4246-b4df-c9382c706782',
      },
    });

    if (response.data.success) {
      const lessons = response.data; // Access lesson data
      return lessons;
    } else {
      console.error('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};


export const GetAllDisplayPages = async () => {
 
  const requestBody = {
    body: {
      response: null,
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '1254d5de-5df2-47cd-ab59-90a9b70fd1c9', 
      },
    });

    if (response.data.success) {
      return response.data.data.data; 
    } else {
      console.error('Error:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};


export const createUserEvent = async (eventType, userEmail, trainingId, courseId = 0, lessonId = 0) => {
  
  
  const requestBody = {
    body: {
      request: {
        event_type: eventType,
        user_email: userEmail,
        training_id: trainingId,
        course_id: courseId,
        lesson_id: lessonId
      }
    },
    tenant_name: tenantName
  };
  console.log({
    event_type: eventType,
    user_email: userEmail,
    training_id: trainingId,
    course_id: courseId,
    lesson_id: lessonId
  },"createUserEvent")
  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6841d510-2c04-4495-a743-345730419cad', 
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in creating user event", error);
    throw error;
  }
};


			
export const getUsersAllTrainingProgramPercent = async (userEmail) => {
  
  const requestBody = {
    body: {user_email: userEmail},
    tenant_name: tenantName
  }

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '356abdb0-c632-4287-9a6e-aa24f07b01f5', 
      },
    });
    
    if (response.data && response.data.success) {
      return response.data.data.data;
    } else {
      console.error("API failed: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching training programs: ", error);
    return null;
  }
};

export const getUserTrainingProgramStatus = async (userEmail, trainingId) => {
  
  
  const requestBody = {
    "body": {
        "user_email": userEmail,
        "training_id": trainingId
    },
    "tenant_name":tenantName
};

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '1a6766ff-eda3-438b-afef-4b8cb2eb3860',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching user trainingProgram status:', error);
    throw error; 
  }
};

export const getUserLessonsStatus = async (userEmail, trainingId, courseId) => {
  
  
  const requestBody = {
    body: {
      request: {
        user_email: userEmail,
        training_id: trainingId,
        course_id: courseId,
      },
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'c029e129-8a47-4d37-80fd-37a4525d37b0',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user lessons status:', error);
    throw error; 
  }
};

export const getToolkitData = async (toolkitName, language) => {

  const requestBody ={
    body: {
      toolkit: toolkitName,
      language: language
    },
    tenant_name: tenantName
  }
  
  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'e289b734-1498-4823-8a4e-fa2abc17fa22', 
      },
    });

    if (response.data.success && response.data.data) {
      return response.data.data.data;
    } else {
      console.error('Error fetching toolkit data:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('API call error:', error);
    return null;
  }
};

export const getCompanyList = async () => {

  const requestBody ={
    "body": {
    },
    "tenant_name": tenantName
}
  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'd558f29e-2d5e-4507-9086-6550fa950fb2', 
      },
    });

    if (response.data.success && response.data.data) {
      return response.data.data.data.data;
    } else {
      console.error('Error fetching company list', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('API call error:', error);
    return null;
  }
};

export const calculateToolkit1 = async (ownership) => {

  const requestBody = {
    body: {
      request: {
        ownership_value: ownership,
        }
    },
    tenant_name: tenantName
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '123d37d0-4e2b-4496-9a2f-bcaad32009d9', 
      },
    });

    if (response.data.success) {
      return response.data.data; 
    } else {
      throw new Error(response.data.message || "API call failed");
    }
  } catch (error) {
    console.error("Error in calculateToolkit1 API call:", error);
    throw error;
  }
};

export const calculateToolkit2 = async (salesValue, expensesValue) => {
  try {
   
    const requestBody = {
      "body": {
          "request": {
              "sales_value": salesValue,
              "expenses_value": expensesValue
          },
          "calculate": null,
          "result": null
      },
      "tenant_name": tenantName
  };
    
  
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '55ffc0a0-db79-4b04-a44a-94a246a98c19', 
      },
    });

    
    return response.data.data;
  } catch (error) {
    console.error('Error in API call:', error);
    return null;
  }
};

export const calculateToolkit3 = async (unitsTwo,saleFour) => {

  const requestBody = {
    body: {
      request: {
        units_two: unitsTwo, 
        sale_four: saleFour, 
      },
      result: null
    },
    tenant_name: tenantName
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '387a7f98-dc40-46a1-96c6-87f32c02288b', 
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error calculating Toolkit3:", error);
    throw error;
  }
};

export const calculateToolkit4 = async (unitSoldDaily) => {
 

  const requestBody = {
    "body": {
        "request": {
           "units_sold_daily": unitSoldDaily,
        },
        "response": null
    },
    "tenant_name": tenantName,
}               

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'edc6d29f-c078-43fc-8c07-7a28a158927b', 
      },
    });
    return response.data; 
  } catch (error) {
    console.error("Error making API call:", error);
    throw error; 
  }
};

export const calculateToolkit5 = async (comValue, expectedValue) => {

	const requestBody = {
    "body": {
        "request": {
          "com_value": comValue,
          "expected_value":expectedValue,
        },
        "calculate": null
    },
    "tenant_name": tenantName
}
  try {
    const response = await axios.post(url, requestBody,		{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'b0bfefa3-9bc5-4bc7-b1a4-e55c4d5634ff', 
      },
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to calculate toolkit data');
    }
  } catch (error) {
    console.error('Error in CalculateToolkit5:', error);
    throw error;
  }
};
export const calculateToolkit6 = async () => {

	const requestBody = {
    "body": {
    },
    "tenant_name": tenantName
}
  try {
    const response = await axios.post(url, requestBody,		{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'e0216586-2785-4361-8d44-6eb33b712bde', 
      },
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error('Failed to calculate toolkit data');
    }
  } catch (error) {
    console.error('Error in CalculateToolkit5:', error);
    throw error;
  }
};
		
export const calculateProgram2Toolkit3 = async (yearOverForecastValue) => {
  const requestBody = {
    "body": {
        "request": {
          year_over_forecast: yearOverForecastValue,
        }
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'c5e3e0f5-02bd-4de3-bbca-651d3aef8664', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit3",error);
  throw error;
}


}
export const calculateProgram2Toolkit4 = async (yearOverForecastValue,cogsSalesForecastValue) => {
  const requestBody = {
    "body": {
        "request": {
            "sales_year": "36450",
            "sales_baseline": "45000",
            "sales_forecast": "",
            "year_over_baseline": "23.5",
            "year_over_forecast": yearOverForecastValue,
            "cog_sold_year": "37695",
            "cog_sold_baseline": "39000",
            "cog_sold_forecast": "",
            "cogs_sales_year": "103.4",
            "cogs_sales_baseline": "86.7",
            "cogs_sales_forecast": cogsSalesForecastValue,
            "gross_profit_year": "-1245",
            "gross_profit_baseline": "6000",
            "gross_profit_forecast": "",
            "gross_margin_year": "-3.4",
            "gross_margin_baseline": "13.3",
            "gross_margin_forecast": ""
        },
        "response": null
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '8e7b6ea1-cae8-43a3-a171-4fcefb4249ae', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit4",error);
  throw error;
}


}
export const calculateProgram2Toolkit5 = async (yearOverForecastValue,cogsSalesForecastValue) => {

  const requestBody = {
    "body": {
        "request": {
            "sales_year": "36450",
            "sales_baseline": "45000",
            "year_over_baseline": "23.5",
            "year_over_forecast": yearOverForecastValue,
            "cog_sold_year": "37695",
            "cog_sold_baseline": "39000",
            "cogs_sales_year": "103.4",
            "cogs_sales_forecast": cogsSalesForecastValue,
            "gross_profit_year": "-1245",
            "gross_profit_baseline": "6000",
            "gross_margin_year": "-3.4",
            "gross_margin_baseline": "13.3",
            "sales_market_baseline": "500",
            "general_admin_baseline": "500",
            "shares_outstanding_value_forecast": "10000",
            "quarterly_operating_cash_flow_quarter_24": "6950",
            "depreciation": "3000",
            "market_px_value": "100"
        },
        "response": null
    },
    "tenant_name": tenantName
}
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '56a9493d-e008-48db-80f7-862a78c1ff20', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in calculateProgram2Toolkit4",error);
  throw error;
}


}
export const CalculatePMOSToolkit1 = async (investmentCommitmentInput) => {
  const requestBody = {
    "body": {
        "request": {
          "investment_commitment_input": investmentCommitmentInput
        },
    },
    "tenant_name": tenantName
}                           
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '26c92246-b513-4647-9ce2-8bc9f7f100aa', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculatePMOSToolkit1",error);
  throw error;
}


}

export const CalculatePMOSToolkit2 = async (invInput,investmentCommitmentDollars,variableExpensesTotalExpenses) => {
  const requestBody = {
    "body": {
        "request": {
            "inv_value": invInput,
            "investment_commitment_dollars":investmentCommitmentDollars,
            "monthly_overhead_23": variableExpensesTotalExpenses,
            "inflation_value": "2",
            "payments_23": "0",
            "payments_33": "120",
            "payments_53": "360",
            "payments_73": "600",
            "c8": "10",
            "return_value": "10",
            "starting_amount": "0"
        },
        "response": null
    },
    "tenant_name": tenantName
}                          
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '51a9a270-9988-4c72-b10d-933c36516834', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculatePMOSToolkit2",error);
  throw error;
}


}


export const CalculatePMOSToolkit3 = async (investedOrSpent,dailyCost,years) => {
  const requestBody = {
    "body": {
      "request": {
        "invested_spent_value": investedOrSpent,
        "years_value": years,
        "daily_cost_value": dailyCost,
        "interest_rate_value": "10"
      }
    },
    "tenant_name": tenantName
  }   
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'fa847346-3236-4d2e-972e-8541f62d5fe4', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculatePMOSToolkit3",error);
  throw error;
}


}


export const CalculateProgram3Toolkit1 = async () => {
  const requestBody = {
    "body": {
      "request": {
        "growth_rate_quarter_27": "0",
        "percent_sales_quarter_27": "72.1"
      }
    },
    "tenant_name": tenantName
  }                         
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'd9522cee-3d32-449e-b539-5b513129785e', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculateProgram3Toolkit1",error);
  throw error;
}


}
export const CalculateProgram3Toolkit3 = async (growthRateQuarter,percentSalesQuarter) => {
  const requestBody = {
    "body": {
      "request": {
        "growth_rate_quarter_27": growthRateQuarter,
        "percent_sales_quarter_27": percentSalesQuarter
      }
    },
    "tenant_name":tenantName
  }                                
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'f282bbc0-73ee-4d42-bb79-34601a3b653a', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculateProgram3Toolkit3",error);
  throw error;
}


}



export const CalculateProgram3Toolkit5 = async (growthRateQuarter,percentSalesQuarter) => {
  const requestBody = {
    "body": {
      "request": {
        "growth_rate_quarter_27": growthRateQuarter,
        "percent_sales_quarter_27": percentSalesQuarter 
      }
    },
    "tenant_name": tenantName
  }                                        
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'b5f835c5-edb5-4916-bfc7-bb7dce888c9e', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculateProgram3Toolkit5",error);
  throw error;
}


}

export const CalculateForecastToolbox = async (growthRateQuarter,percentSalesQuarter,companySymbol,userEmail) => {
  const requestBody = 			{
    "body": {
      "request": {
        "growth_rate_quarter_27": growthRateQuarter,
        "percent_sales_quarter_27": percentSalesQuarter  
      },
      "user_email":userEmail,
      "Company_Symbol": companySymbol,	
    },
    "tenant_name": tenantName
  }	                                       
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'fc22477f-6959-48d5-b4f4-b5bb7e8f055b', 
    },
  })
  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to calculate toolkit data")
  }
}
catch(error){
  console.error("Error in CalculateForecastToolbox",error);
  throw error;
}


}

export const getStudentConfigData = async (userEmail) => {
  
  const requestBody = {
      "body": {
          "user_email": userEmail
      },
      "tenant_name": tenantName
  }                                 
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': '34f39c0c-3c32-403d-a515-d342baa14c63', 
    },
  })

  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to getStudentConfigData")
  }
}
catch(error){
  console.error("Error in getStudentConfigData",error);
  throw error;
}


}
export const updateStudentForecastConfigData = async (userEmail,companySymbol) => {
  const requestBody = {
    "body": {
        "request": {
            "user_email": userEmail,
            "symbol": companySymbol
        },
    },
    "tenant_name": tenantName
}
                                 
  
try{
  const response = await axios.post(url,requestBody,{
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'fe23762a-8ff2-45f9-b487-47b54b5095c6', 
    },
  })

  if(response.data.success){
    return response.data.data;
  }
  else{
    throw new Error("Failed to updateStudentForecastConfigData")
  }
}
catch(error){
  console.error("Error in updateStudentForecastConfigData",error);
  throw error;
}


}


export const CalculateIVoTMoneyGameofLife = async ( bonusInput,
                bonusDecisionOne,
                bonusDecisionTwo,
                bonusDecisionThree,
                monthlyIncome,
                lifeStyleSpendDecisionOne,
                lifeStyleSpendDecisionTwo,
                lifeStyleSpendDecisionThree,
                dailyHabitsSpendDecisionOne,
                dailyHabitsSpendDecisionTwo,
                dailyHabitsSpendDecisionThree,
                impulseSpendDecisionOne,
                impulseSpendDecisionTwo,
                impulseSpendDecisionThree,
                beginInput,
                endInput) => {

                const requestBody = {
                  "body": {
                      "request": {
                          "begin_value": beginInput,
                          "end_value": endInput,
                          "int_rate_value": "10",
                          "bonus_value": bonusInput,
                          "monthly_income_value": monthlyIncome,
                          "dec_one_value": bonusDecisionOne,
                          "dec_two_value": bonusDecisionTwo,
                          "dec_three_value":bonusDecisionThree,
                          "lifestyle_dec_one": lifeStyleSpendDecisionOne,
                          "lifestyle_dec_two": lifeStyleSpendDecisionTwo,
                          "lifestyle_dec_three": lifeStyleSpendDecisionThree,
                          "habits_dec_one":dailyHabitsSpendDecisionOne,
                          "habits_dec_two": dailyHabitsSpendDecisionTwo,
                          "habits_dec_three":dailyHabitsSpendDecisionThree,
                          "impulse_dec_one": impulseSpendDecisionOne,
                          "impulse_dec_two": impulseSpendDecisionTwo,
                          "impulse_dec_three": impulseSpendDecisionThree,
                          
                      },
                      "response": null
                  },
                  "tenant_name": tenantName
              }                                     
                
              try{
                const response = await axios.post(url,requestBody,{
                  headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': '60f99938-4197-497a-badc-416d66b8786c', 
                  },
                })
             
                if(response.data.success){
                  return response.data.data;
                }
                else{
                  throw new Error("Failed to calculate IVoTMoneyGameofLife toolkit data")
                }
              }
              catch(error){
                console.error("Error in IVoTMoneyGameofLife",error);
                throw error;
              }


              }


export const forgotPasswordAPI = async (userEmail) => {
  const requestBody = {
    body: {
      request: {
        user_email: userEmail,
      },
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'bbe58a84-81d0-4a98-9901-9326a462622b', 
      },
    });
    return response.data; 
  } catch (error) {
    console.error('Error in forgot password API:', error);
    throw error; 
  }
};

export const getPrintTemplate = async (functionName) => {
  try {
      const requestBody = {
          body: {
              function_name: functionName
          },
          tenant_name: tenantName
      };

      const response = await axios.post(url, requestBody,{
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '0897f637-cf0a-4077-836f-bbbb575a1821', 
        },
      });

      if (response.data.success && response.data.data.length > 0) {
          const displayFields = response.data.data[0].display_fields;

          if (displayFields && displayFields[0].printTemplate) {
              return displayFields[0].printTemplate;
          } else {
              throw new Error("printTemplate not found in display_fields.");
          }
      } else {
          throw new Error("No data found or request failed.");
      }
  } catch (error) {
      console.error("Error fetching print template:", error.message);
      return null;
  }
};


export const GetPartnerDetails = async (campaignName) =>{
  const requestBody = 	{
    "body": {
      "response": null,
      "campaign_name": campaignName
    },
    "tenant_name": tenantName
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'e1909ec5-93c0-43e4-bfd0-1efab0c6196c', 
      },
    });

    return response.data.data; 
  } catch (error) {
    console.error('Error in fetching Partner Details', error);
    throw error; 
  }
};


export const GetAllSubscriptions = async () =>{
    const requestBody = {
      "body": {},
      "tenant_name": tenantName
  };

  try {
    const response = await axios.post(url, requestBody,{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '1e0bfcee-0e03-430b-9dba-866b85df2bd7', 
      },
    });

    return response.data.data.data.data; 
  } catch (error) {
    console.error('Error in fetching all Subscriptions', error);
    throw error; 
  }
};

export const GetPaymentStatus = async (userEmail, timestamp) => {
  const requestBody = {
    body: {
      request: {
        user_email: userEmail,
        timestamp: timestamp,
      },
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "3e00185a-3aab-4120-bad2-26855dfedc3e",
      },
    });
    
    return response.data?.data; 
  } catch (error) {
    console.error("Error in fetching payment status:", error);
    throw error;
  }
};

export const GetAllCountries = async () => {
  const requestBody = {
    body: {
      response: null,
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "e32b5497-59d6-42a3-ad08-5622281ce1dc",
      },
    });
    
    return response.data?.data?.data?.data; 
  } catch (error) {
    console.error("Error in fetching all countries:", error);
    throw error;
  }
};


export const GetAllCitiesFromCountry = async (country) => {

  const requestBody = {
    body: {
      response: null,
      country: country,
    },
    tenant_name: tenantName,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "46f93d4c-4101-43e9-bc9e-e58fcaa1037f",
      },
    });
    return response.data.data.data; 
  } catch (error) {
    console.error("Error in fetching cities from country:", error);
    throw error;
  }
};
