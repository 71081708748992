import React,{useState} from "react";
import PropTypes from "prop-types";
import "./tool.css";
import { useSelector } from "react-redux";
import { CalculateIVoTMoneyGameofLife } from "../../../../../api/programApi";
import {formatWithDollarAndCommas,stripFormatting,formatNumberWithCommasAndMillions} from "../../../../../utils/helper";


const IVoTMoneyGameofLife = ({ data }) => {
  
    const userName = useSelector((state) => state.userSlice.name);
    const [calculatedValue,setCalculatedValue] = useState(null);
    
    const [beginInput, setBeginInput] = useState("20");
    const [endInput, setEndInput] = useState("70");
    const [bonusInput, setBonusInput] = useState("$5,000");
    const [bonusDecisionOne, setBonusDecisionOne] = useState("$0");
    const [bonusDecisionTwo, setBonusDecisionTwo] = useState("$0");
    const [bonusDecisionThree, setBonusDecisionThree] = useState("$0");
    const [monthlyIncome, setMonthlyIncome] = useState("$3,000");
    const [lifeStyleSpendDecisionOne, setLifeStyleSpendDecisionOne] = useState("$1,500");
    const [lifeStyleSpendDecisionTwo, setLifeStyleSpendDecisionTwo] = useState("$0");
    const [lifeStyleSpendDecisionThree, setLifeStyleSpendDecisionThree] = useState("$0");
    const [dailyHabitsSpendDecisionOne, setDailyHabitsSpendDecisionOne] = useState("$10");
    const [dailyHabitsSpendDecisionTwo, setDailyHabitsSpendDecisionTwo] = useState("$0");
    const [dailyHabitsSpendDecisionThree, setDailyHabitsSpendDecisionThree] = useState("$0");
    const [impulseSpendDecisionOne, setImpulseSpendDecisionOne] = useState("$0");
    const [impulseSpendDecisionTwo, setImpulseSpendDecisionTwo] = useState("$0");
    const [impulseSpendDecisionThree, setImpulseSpendDecisionThree] = useState("$0");
    
    
    const handleBeginInputChange = (e) => {
    setBeginInput(e.target.value);
    };

    const handleEndInputChange = (e) => {
    setEndInput(e.target.value);
    };

    const handleBonusInputChange = (e) => {
    setBonusInput(e.target.value);
    };

    const handleBonusDecisionOneChange = (e) => {
    setBonusDecisionOne(e.target.value);
    };

    const handleBonusDecisionTwoChange = (e) => {
    setBonusDecisionTwo(e.target.value);
    };

    const handleBonusDecisionThreeChange = (e) => {
    setBonusDecisionThree(e.target.value);
    };

    const handleMonthlyIncomeChange = (e) => {
    setMonthlyIncome(e.target.value);
    };

    const handleLifeStyleSpendDecisionOneChange = (e) => {
    setLifeStyleSpendDecisionOne(e.target.value);
    };

    const handleLifeStyleSpendDecisionTwoChange = (e) => {
    setLifeStyleSpendDecisionTwo(e.target.value);
    };

    const handleLifeStyleSpendDecisionThreeChange = (e) => {
    setLifeStyleSpendDecisionThree(e.target.value);
    };

    const handleDailyHabitsSpendDecisionOneChange = (e) => {
    setDailyHabitsSpendDecisionOne(e.target.value);
    };

    const handleDailyHabitsSpendDecisionTwoChange = (e) => {
    setDailyHabitsSpendDecisionTwo(e.target.value);
    };

    const handleDailyHabitsSpendDecisionThreeChange = (e) => {
    setDailyHabitsSpendDecisionThree(e.target.value);
    };

    const handleImpulseSpendDecisionOneChange = (e) => {
    setImpulseSpendDecisionOne(e.target.value);
    };

    const handleImpulseSpendDecisionTwoChange = (e) => {
    setImpulseSpendDecisionTwo(e.target.value);
    };

    const handleImpulseSpendDecisionThreeChange = (e) => {
    setImpulseSpendDecisionThree(e.target.value);
    };  


    const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    return field ? field.field_value : "";
  };

  const handleBlur = async () => {
    try {
        setBonusInput((prev) => formatWithDollarAndCommas(prev));
        setBonusDecisionOne((prev) => formatWithDollarAndCommas(prev));
        setBonusDecisionTwo((prev) => formatWithDollarAndCommas(prev));
        setBonusDecisionThree((prev) => formatWithDollarAndCommas(prev));
        setMonthlyIncome((prev) => formatWithDollarAndCommas(prev));
        setLifeStyleSpendDecisionOne((prev) => formatWithDollarAndCommas(prev));
        setLifeStyleSpendDecisionTwo((prev) => formatWithDollarAndCommas(prev));
        setLifeStyleSpendDecisionThree((prev) => formatWithDollarAndCommas(prev));
        setDailyHabitsSpendDecisionOne((prev) => formatWithDollarAndCommas(prev));
        setDailyHabitsSpendDecisionTwo((prev) => formatWithDollarAndCommas(prev));
        setDailyHabitsSpendDecisionThree((prev) => formatWithDollarAndCommas(prev));
        setImpulseSpendDecisionOne((prev) => formatWithDollarAndCommas(prev));
        setImpulseSpendDecisionTwo((prev) => formatWithDollarAndCommas(prev));
        setImpulseSpendDecisionThree((prev) => formatWithDollarAndCommas(prev));

          

        const response = await CalculateIVoTMoneyGameofLife(stripFormatting(bonusInput),
        stripFormatting(bonusDecisionOne),
        stripFormatting(bonusDecisionTwo),
        stripFormatting(bonusDecisionThree),
        stripFormatting(monthlyIncome),
        stripFormatting(lifeStyleSpendDecisionOne),
        stripFormatting(lifeStyleSpendDecisionTwo),
        stripFormatting(lifeStyleSpendDecisionThree),
        stripFormatting(dailyHabitsSpendDecisionOne),
        stripFormatting(dailyHabitsSpendDecisionTwo),
        stripFormatting(dailyHabitsSpendDecisionThree),
        stripFormatting(impulseSpendDecisionOne),
        stripFormatting(impulseSpendDecisionTwo),
        stripFormatting(impulseSpendDecisionThree),
        beginInput,
        endInput)
        
  

        setCalculatedValue({
            total_value:response?.total_value,
            remaining_value:response?.remaining_value,
            lost_value:response?.lost_value,
            wealth_value:response?.wealth_value,
            lifestyle_total:response?.lifestyle_total,
            lifestyle_remaining:response?.lifestyle_remaining,
            lifestyle_lost:response?.lifestyle_lost,
            habits_total:response?.habits_total,
            habits_remaining:response?.habits_remaining,
            habits_lost:response?.habits_lost,
            impulse_total:response?.impulse_total,
            impulse_remaining:response?.impulse_remaining,
            impulse_lost:response?.impulse_lost,
            total_spend:response?.total_spend,
            remaining_spend:response?.remaining_spend,
            lost_wealth:response?.lost_wealth,
            funds_wealth:response?.funds_wealth,
        });

    } catch (error) {
        console.error("Error calculating IVoTMoneyGameofLife value", error);
    }
};  

 

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        <table className="tool-table">
          <thead>
            <tr>
              <th colSpan="1" className="background-blue font-white">
                     IVoT
              </th>
              <th colSpan="4" className="background-blue font-white">
                {getField("lesson_header")}
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Name Row */}
            <tr className="background-deep-blue font-white">
              <th>{getField("name")}</th>
              <th>{getField("begin")}</th>
              <th>{getField("end")}</th>
              <th>{getField("int_rate")}</th>
              <th></th>
            </tr>
            <tr>
              <td>{userName}</td>
              <td><input
                className="form-input"
                type="text"
                value={beginInput}
                onChange={handleBeginInputChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={endInput}
                onChange={handleEndInputChange}
                onBlur={handleBlur}
                /></td>
              <td className="td-tool-value">{getField("int_rate_value")}%</td>
              <td></td>
            </tr>

            {/* Bonus Row */}
            <tr className="background-deep-blue font-white">
              <th>{getField("bonus")}</th>
              <th>{getField("total")}</th>
              <th>{getField("remaining")} </th>
              <th>{getField("wealth")}</th>
              <th>{getField("lost")}</th>
            </tr>
            <tr>
              <td><input
                className="form-input"
                type="text"
                value={bonusInput}
                onChange={handleBonusInputChange}
                onBlur={handleBlur}
                /></td>
                <td className="exchange-rate-result">{calculatedValue?.total_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_value)}` : ""}</td>
                <td className="exchange-rate-result">{calculatedValue?.remaining_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.remaining_value)}` : ""}</td>
                <td className="exchange-rate-result">{calculatedValue?.wealth_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.wealth_value)}` : ""}</td>
                <td className="exchange-rate-result">{calculatedValue?.lost_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.lost_value)}` : ""}</td>
            </tr>

            {/* Decision Row */}
            <tr className="background-deep-blue font-white">
              <td></td>
              <td>{getField("dec_one")}</td>
              <td>{getField("dec_two")}</td>
              <td>{getField("dec_three")}</td>
              <td>{getField("total")}</td>
            </tr>
            <tr>
              <td>{getField("e_s")}</td>
              <td><input
                className="form-input"
                type="text"
                value={bonusDecisionOne}
                onChange={handleBonusDecisionOneChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input"
                type="text"
                value={bonusDecisionTwo}
                onChange={handleBonusDecisionTwoChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input"
                type="text"
                value={bonusDecisionThree}
                onChange={handleBonusDecisionThreeChange}
                onBlur={handleBlur}
                /></td>
                           <td className="exchange-rate-result">{calculatedValue?.total_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_value)}` : ""}</td>

            </tr>

            {/* Monthly Income Row */}
            <tr className="background-blue font-white">
              <th>{getField("monthly")}</th>
              <th colSpan="2">{getField("spend")}</th>
              <th colSpan="3">{getField("wealth")}</th>
            </tr>

            <tr className="background-deep-blue font-white">
              <th>{getField("income")}</th>
              <th>{getField("total")}</th>
              <th>{getField("remaining")} </th>
              <th>{getField("funds")}</th>
              <th>{getField("lost")}</th>
            </tr>

            <tr>
              <td><input
                className="form-input "
                type="text"
                value={monthlyIncome}
                onChange={handleMonthlyIncomeChange}
                onBlur={handleBlur}
                /></td>
              <td className="exchange-rate-result">{calculatedValue?.total_spend !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_spend)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.remaining_spend !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.remaining_spend)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.funds_wealth !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.funds_wealth)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.lost_wealth !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.lost_wealth)}` : ""}</td>
            </tr>

            {/* Spend Details Row */}
            <tr className="background-deep-blue font-white">
              <th>{getField("spend")}</th>
              <th>{getField("lifestyle")} </th>
              <th>{getField("habits")}</th>
              <th>{getField("impulse")}</th>
              <th></th>
            </tr>
            <tr>
              <th>{getField("dec_one")}</th>
              <td><input
                className="form-input "
                type="text"
                value={lifeStyleSpendDecisionOne}
                onChange={handleLifeStyleSpendDecisionOneChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={dailyHabitsSpendDecisionOne}
                onChange={handleDailyHabitsSpendDecisionOneChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={impulseSpendDecisionOne}
                onChange={handleImpulseSpendDecisionOneChange}
                onBlur={handleBlur}
                /></td>
              <td></td>
            </tr>

            {/* Remaining Wealth Row */}
            <tr>
              <th>{getField("dec_two")}</th>
              <td><input
                className="form-input "
                type="text"
                value={lifeStyleSpendDecisionTwo}
                onChange={handleLifeStyleSpendDecisionTwoChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={dailyHabitsSpendDecisionTwo}
                onChange={handleDailyHabitsSpendDecisionTwoChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={impulseSpendDecisionTwo}
                onChange={handleImpulseSpendDecisionTwoChange}
                onBlur={handleBlur}
                /></td>
              <td></td>
            </tr>
            {/* Remaining Wealth Row */}
            <tr>
              <th>{getField("dec_three")}</th>
              <td><input
                className="form-input "
                type="text"
                value={lifeStyleSpendDecisionThree}
                onChange={handleLifeStyleSpendDecisionThreeChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={dailyHabitsSpendDecisionThree}
                onChange={handleDailyHabitsSpendDecisionThreeChange}
                onBlur={handleBlur}
                /></td>
              <td><input
                className="form-input "
                type="text"
                value={impulseSpendDecisionThree}
                onChange={handleImpulseSpendDecisionThreeChange}
                onBlur={handleBlur}
                /></td>
              <td></td>
            </tr>

            <tr>
              <th>{getField("total")}</th>
              <td className="exchange-rate-result">{calculatedValue?.lifestyle_total !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.lifestyle_total)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.habits_total !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.habits_total)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.impulse_total !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.impulse_total)}` : ""}</td>
              <td></td>
            </tr>
            <tr>
              <th>{getField("remaining")}</th>
              <td className="exchange-rate-result">{calculatedValue?.lifestyle_remaining !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.lifestyle_remaining)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.habits_remaining !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.habits_remaining)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.impulse_remaining !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.impulse_remaining)}` : ""}</td>
              <td></td>
            </tr>
            <tr>
              <th>{getField("wealth_lost")}</th>
              <td className="exchange-rate-result">{calculatedValue?.lifestyle_lost !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.lifestyle_lost)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.habits_lost !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.habits_lost)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.impulse_lost !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.impulse_lost)}` : ""}</td>
              <td></td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  );
};

IVoTMoneyGameofLife.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default IVoTMoneyGameofLife;
