import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items,logo }) => {
  return (
    <nav className="custom-breadcrumb container" aria-label="breadcrumb">
      <ol className="breadcrumb" style={{flexWrap:"nowrap"}}>
      {logo && (
          <li className="breadcrumb-logo">
            <img src={logo} alt="Logo" className="breadcrumb-logo-img" />
          </li>
        )}
        {items.map((item, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${
              index === items.length - 1 ? "active" : ""
            }`}
            aria-current={index === items.length - 1 ? "page" : undefined}
          >
            {item.to ? (
              <Link to={item.to}>{item.label}</Link>
            ) : (
              <span>{item.label}</span>
            )}
            {/* {index !== items.length - 1 && <span className="separator">|</span>} */}
          </li>
        ))}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string, // If `to` is not provided, it's the current page
    })
  ).isRequired,
  logo: PropTypes.string, // Optional logo image
};

export default Breadcrumb;
