import React,{useEffect} from 'react';
import Header from "../../student/header";
import { useLocation,useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import { hideConfetti,showConfetti } from "../../../components/common/redux/confettiSlice";
import { useWindowSize } from 'react-use';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const ThankYouPage = () => {
  const { width, height } = useWindowSize();
  const showConfettiState = useSelector((state) => state.confetti.show);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  
  const { trainingName, isCertificate} = location.state || {};

  useEffect(() => {
    dispatch(showConfetti());
  }, [dispatch]);

  return (
    <>
                  {showConfettiState && (
                  <Confetti
                    recycle={false}
                    numberOfPieces={1000} 
                    width={width} 
                    height={height}
                    gravity={0.5} 
                    onConfettiComplete={() => dispatch(hideConfetti())}
                  />
                )}
    <div className="main-wrapper">
        <Header />
        <div className="page-content bg-light-grayish-blue vh-100 d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card p-4 shadow-sm text-center">
                  <h1 className="theme-color">🎉 {t("congratulations")} 🎉</h1>
                  <p className="mt-3 paragraph-text">
                  {t("trainingCompleted")} <span className="paragraph-bold">{trainingName}</span> {t("trainingProgram")}.
                  </p>
                  {isCertificate && (
                    <p className="certificate-text">
                      {t("certificateIssued")}
                    </p>
                  )}
                </div>
              </div>
            </div>
                  <div className="text-center mt-5">
            <hr />
            <button
              className="btn btn-primary bg-ivot-primary "
              onClick={() => navigate('/student/student-dashboard')}
            >
             {t("goToDashboard")}
            </button>
          </div>
          </div>
        </div>


      </div>

    </>
  );
};

export default ThankYouPage;


