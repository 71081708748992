import React,{useState} from "react";
import PropTypes from "prop-types";
import PMOSToolkit1 from "../../../components/pages/course/courseDetails/tools/PMOSToolkit1";
import PMOSToolkit2 from "../../../components/pages/course/courseDetails/tools/PMOSToolkit2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const PersonalMoneyTools = ({ toolkitData1, toolkitData2 }) => {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleShowModal = (content) => {
      setModalContent(content);
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
      setModalContent(null);
    };
    
  return (
    
      <div className="row">
        {/* Render PMOSToolkit1 */}
        <div className="col-md-4">
          {toolkitData1 ? (
            <div onClick={() => handleShowModal(<PMOSToolkit1 data={toolkitData1} />)}>
              <PMOSToolkit1 data={toolkitData1} />
            </div>
          ) : (
            <div>Loading PMOSToolkit1...</div>
          )}
        </div>

        {/* Render PMOSToolkit2 */}
          <div className="col-md-5">
          {toolkitData2 ? (
            <div onClick={() => handleShowModal(<PMOSToolkit2 data={toolkitData2} />)}>
              <PMOSToolkit2 data={toolkitData2} />
            </div>
          ) : (
            <div>Loading PMOSToolkit2...</div>
          )}
        </div>

         {/* Modal for displaying tools */}
      <Modal show={showModal} onHide={handleCloseModal} fullscreen>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      </div>
    
  );
};

PersonalMoneyTools.propTypes = {
  toolkitData1: PropTypes.object,
  toolkitData2: PropTypes.object,
};

export default PersonalMoneyTools;
