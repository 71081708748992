/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { Home, LogOut, Moon, Star, User,HelpCircle ,Tool  } from "react-feather";
import { Link } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
// import LanguageSwitcher from "../../button/LanguageSwitcher";
import { useSelector } from 'react-redux';
import {
  Course14,
  Course15,
  Course4,
  IVoTLogo,
  User11,
  User16,
} from "../../imagepath";
// import DarkMode from "../../common/darkMode";
import useLogout from "../../../hooks/useLogout";
import { selectPartnerLogo } from '../../../components/common/redux/partnerSlice';
// eslint-disable-next-line react/prop-types
export default function StudentHeader({ activeMenu }) {
  const storedAvatar = useSelector((state) => state.userSlice.avatar) || localStorage.getItem("userAvatar");
  const { handleLogoutAndRedirect } = useLogout();
  const userName = useSelector((state) => state.userSlice.name);
  const [navbar, setNavbar] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showWish, setShowWish] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const partnerLogo = useSelector(selectPartnerLogo);

    // Mobile Menu toggle
    const [mobileMenu, setMobileMenu] = useState(false);
    const [mobileSubMenu, setMobileSubMenu] = useState(false);
    const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
    const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
    const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
    const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
    const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
    const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
    const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
    const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  
    
  
    const openMobileMenu = () => {
      document.body.classList.add("menu-opened");
      setMobileMenu(true);
    };
    const hideMobileMenu = () => {
      document.body.classList.remove("menu-opened");
      setMobileMenu(false);
    };
  
    const openMobileSubMenu = (e) => {
      e.preventDefault();
      setMobileSubMenu(!mobileSubMenu);
    };
    const openMobileSubMenu2 = (e) => {
      e.preventDefault();
      setMobileSubMenu2(!mobileSubMenu2);
    };
    const openMobileSubMenu22 = (e) => {
      e.preventDefault();
      setMobileSubMenu22(!mobileSubMenu22);
    };
    const openMobileSubMenu3 = (e) => {
      e.preventDefault();
      setMobileSubMenu3(!mobileSubMenu3);
    };
    const openMobileSubMenu32 = (e) => {
      e.preventDefault();
      setMobileSubMenu32(!mobileSubMenu32);
    };
    const openMobileSubMenu4 = (e) => {
      e.preventDefault();
      setMobileSubMenu4(!mobileSubMenu4);
    };
    const openMobileSubMenu42 = (e) => {
      e.preventDefault();
      setMobileSubMenu42(!mobileSubMenu42);
    };
    const openMobileSubMenu43 = (e) => {
      e.preventDefault();
      setMobileSubMenu43(!mobileSubMenu43);
    };
    const openMobileSubMenu5 = (e) => {
      e.preventDefault();
      setMobileSubMenu5(!mobileSubMenu5);
    };
  // To close the modal, when clicked outside anywhere
  const cart = useRef();
  useOnClickOutside(cart, () => setShowCart(false));

  const wish = useRef();
  useOnClickOutside(wish, () => setShowWish(false));

  const notification = useRef();
  useOnClickOutside(notification, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  // Cart Click
  const cartClick = (e) => {
    e.preventDefault();
    // if (showWish) {
    //   setShowWish(false);
    // }
    setShowCart(!showCart);
    console.log(showCart);
  };

  const wishClick = (e) => {
    e.preventDefault();
    // if (showCart) {
    //   setShowCart(false);
    // }
    setShowWish(!showWish);
  };

  const notificationClick = (e) => {
    e.preventDefault();
    setShowNotification(!showNotification);
  };
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);



  return (
    <header className="header header-page"> 
      <div className="header-fixed bg-ivot-primary">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container ">
            <div className="navbar-header">
              <Link to="/student/student-dashboard" className="navbar-brand logo">
                <img src={IVoTLogo} className="img-fluid" alt="logo" />
              </Link>
              {partnerLogo.length > 0 && (
                <img src={partnerLogo[0]} alt="Partner Logo" style={{ marginLeft: '10px', height: '50px' }} />
              )}
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/home" className="menu-logo">
                  <img src={IVoTLogo} className="img-fluid image-shadow" alt="logo" />
                </Link>
              </div>
              
            </div>
            <ul className="nav header-navbar-rht" style={{ 'position': 'absolute', right: '25px'}}>
            {/* <LanguageSwitcher navbar={false} isFixed={true} />
            <DarkMode/> */}
             
              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle d-flex align-items-center  show" : "dropdown-toggle d-flex align-items-center "
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                    <img src={storedAvatar || User16} alt="" />
                    <span className="status online"></span>
                  </span>
                  {/* Add solid triangle here */}
                  <span style={{ marginLeft: '8px', display: 'inline-flex', alignItems: 'center' }}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 16L6 8H18L12 16Z" />
                    </svg>
                  </span>

                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={storedAvatar || User16}
                        alt="User Image"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>{userName}</h6>
                      <p className="text-muted text mb-0">Student</p>
                    </div>
                  </div>
                  <Link
                    className="dropdown-item text"
                    to="/student/student-dashboard"
                  >
                    <Home size={14} color={"#FF875A"} className="headerIcon me-1" />{" "}
                    Dashboard
                  </Link>
                  <Link
                    className="dropdown-item text"
                    to="/student/student-setting"
                  >
                    <User size={14} color={"#FF875A"} className="headerIcon me-1" />{" "}
                    Profile
                  </Link>
                  <Link
                    className="dropdown-item text"
                    to="/student/student-toolbox"
                  >
                    <Tool size={14} color={"#FF875A"} className="headerIcon me-1" />{" "}
                    Toolbox
                  </Link>
                  <Link
                    className="dropdown-item text"
                    to="/student/student-support"
                  >
                    <HelpCircle size={14} color={"#FF875A"} className="headerIcon me-1" />{" "}
                    Support
                  </Link>
                  
                  <Link className="dropdown-item text" to="/home" onClick={(e) => {
                    e.preventDefault();
                    handleLogoutAndRedirect(); 
                  }}>
                    <LogOut
                      size={14}
                      color={"#FF875A"}
                      className="headerIcon me-1"
                    />{" "}
                    Logout
                  </Link>
                {/* <div className="dropdown-item text night-mode">
                    <span>
                      <Moon size={14} className="headerIcon" /> Night Mode{" "}
                    </span>
                    <div className="form-check form-switch check-on m-0">
                      <input
                        className="form-check-input focusShadow"
                        type="checkbox"
                        id="night-mode"
                      />
                    </div>
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </nav>
        {/* <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div> */}
      </div>
    </header>
  );
}
