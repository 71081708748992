
import React from 'react';
import PropTypes from "prop-types";

const LessonPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  // Calculate the range of visible page numbers
  const getVisiblePages = () => {
    const visiblePages = [];
    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) visiblePages.push(i);
    } else {
      if (currentPage === 1) {
        visiblePages.push(1, 2, 3);
      } else if (currentPage === totalPages) {
        visiblePages.push(totalPages - 2, totalPages - 1, totalPages);
      } else {
        visiblePages.push(currentPage - 1, currentPage, currentPage + 1);
      }
    }
    return visiblePages;
  };

  const visiblePages = getVisiblePages();

  return (
    <nav aria-label="Pagination">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(1)}>
            &laquo;&laquo;
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
            &lsaquo;
          </button>
        </li>
        {visiblePages.map((page) => (
          <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageClick(page)}>
              {page}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
            &rsaquo;
          </button>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageClick(totalPages)}>
            &raquo;&raquo;
          </button>
        </li>
      </ul>
    </nav>
  );
};

LessonPagination.propTypes = { 
  totalPages: PropTypes.number.isRequired, 
  currentPage: PropTypes.number.isRequired, 
  onPageChange: PropTypes.func.isRequired,
};

export default LessonPagination;
